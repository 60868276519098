import { ButtonType, LinkType } from 'components/Header/types';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { DateTime } from 'luxon';

export enum DESIGN_VERSION {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

export enum THEMES {
   DEFAULT = 'default',
   WHITE = 'white',
   BEIGE = 'beige',
   BLACK = 'black',
   GREEN = 'green',
   ORANGE = 'orange',
   BLUE = 'blue',
   GREY1 = 'grey1',
   LIGHTGREY = 'lightGrey',
   MAINCOLOR = 'mainColor',
   TEXTCOLOR = 'textColor',
   TRANSPARENT = 'transparent'
}

export type RichTextType = RenderRichTextData<ContentfulRichTextGatsbyReference>;

type Coordinates = {
  lat: number;
  lon: number;
};

export type ContentfulAsset = {
  id: string;
  __typename: string;
  svg: string | null;
  title: string;
  description?: string;
  file: {
    url: string;
    contentType: string;
    details: {
      image?: {
        width: number;
        height: number;
      };
    };
  };
  gatsbyImageData: IGatsbyImageData;
};

export type CTA = {
  cta: string;
  ctaExternalLinkText: string;
  ctaBookingText: string;
  ctaSubtext: string;
  ctaExternalLinkUrl: string;
  promoText: string | null;
};

export type ContentfulLocation = {
  __typename: string;
  id: string;
  displayName: string;
  clinicName: string;
  internalName: string;
  cta: CTA;
  emailListSourceKey: string;
  mainImage: ContentfulAsset;
  address: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  coordinates: Coordinates;
  slug: string;
  status: string;
  openStatusColor: string;
  openStatusText: string;
  comingSoonStatusText: string;
  text: {
      raw: string;
  };
  sections: Record<string, unknown>[];
};

export type ContentfulRegion = {
  __typename: string;
  id: string;
  slug: string;
  displayName: string;
  regionName: string;
  waitlistShortName: string | null;
  statusText: string;
  city: string;
  coordinates: Coordinates;
  emailListSourceKey: string | null;
  locations: ContentfulLocation[];
};

export type PressItemType = {
  id: string;
  internalName: string;
  publicationLogo: ContentfulAsset;
  publicationTitle?: string;
  articleTitle?: string;
  datePublished: DateTime;
  url: string
}

export type ContentListType = {
  id: string;
  internalName: string;
  theme: THEMES;
  designSystem: DESIGN_VERSION;
  headline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  action: LinkType | ButtonType;
  hideActionOnDesktop?: boolean;
  width: string;
  items: PressItemType[]
}

export type TeamMemberType = {
  id: string;
  internalName: string;
  media: ContentfulAsset;
  name: string;
  role: string;
  location: string;
  education: string;
  areaOfExpertise: string;
  areasOfInterest: string;
  biography: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  sections: ContentListType[]
}

export type TickerTextType = {
  id: string;
  internalName?: string;
  eyebrow?: string;
  tickingText?: RichTextType;
  actions?: LinkType[] | ButtonType[]
}

type linkToType = {
  linkToPage: {
    subdomain?: string;
    slug: string
  }
}

export type NavigationLinkType = {
  id: string;
  internalName?: string;
  label: string;
  to: linkToType;
  toExternalUrl?: string
  action?: string
}

export type NavigationMenuType = {
  id: string;
  internalName?: string;
  heading?: string;
  navigationLinks: NavigationLinkType[]
}

import { rgba } from 'polished';

// Site Specific Colors
export const periwinkle = '#5474AB';
export const rust = '#C17A44';
export const olive = '#648251';
export const beige = '#F2F1ED';
export const creme = beige; // #F2F1ED
export const red = '#AD0F2C';
export const bone = '#FCFAF5';

export const grey7 = '#DFDFDF';
export const grey6 = '#535353';
export const grey5 = '#2B2B2B';
export const grey4 = '#818181';
export const grey3 = '#C4C4C4';
export const grey2 = '#EAEAEA';
export const grey1 = '#F5F5F5';
export const kioskGrey = '#FAFAFA';

export const black = grey5;
export const white = '#fff';

// Basic Colors
export const transparent = 'transparent';
export const currentcolor = 'currentcolor';
export const bgColor = white;
export const mainColor = grey5;
export const alert = rust;
export const notify = periwinkle;
export const success = olive;
export const textColor = grey5;
export const lightTextColor = rgba(textColor, 0.4);
export const lightGrey = '#F2F2F2';
export const hrColor = rgba(textColor, 0.1);
export const darkGrey25 = rgba(grey5, 0.25);
export const darkGrey6 = rgba(grey5, 0.06);
export const formFieldError = red;
export const secondaryText = '#535353';
export const tertiaryText = '#818181';

// Color Variations
export const periwinkleLighten = '#EAEEF4';
export const periwinkleDarken = '#5675AC';
export const periwinkleVeryLight = '#E6EAF2';

import React from 'react';

import { THEMES } from 'types';

export type AppState = {
    navigationOpen: boolean;
    popupOpen: boolean;
    popupId: string | null;
    popupData: Record<string, unknown> | null;
    pageTheme: THEMES;
    openNavigation: ()=> void;
    closeNavigation: ()=> void;
    toggleNavigation: ()=> void;
    setPopupData: (popupData: Record<string, unknown> | null)=> void;
    setPageTheme: (pageTheme: THEMES)=> void;
    togglePopup: (id: string | null)=> void;
}

const AppContext = React.createContext<AppState | undefined>(undefined);
export default AppContext;
